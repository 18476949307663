import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ZlanCurrentTournament,
  ZlanEntry,
  ZlanMyTeam,
  ZlanPlayer,
  ZlanTeam,
  ZlanTournament,
  ZlanUserInfo
} from '../../types/zlan';
import { environment } from '@Env';
import { catchError, map, mergeMap, Observable, of, share, switchMap, tap, throwError } from 'rxjs';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  ready = false;
  zlanProfile: ZlanUserInfo | null = null;
  tournament: ZlanCurrentTournament | null = null;
  myTeam: ZlanMyTeam | null = null;
  registrations: ZlanEntry[] = [];
  tournamentLoad$: Observable<ZlanCurrentTournament> | null = null;
  loadProfile$: Observable<ZlanUserInfo> | null = null;

  constructor(private http: HttpClient, private platform: PlatformService) {}

  init() {
    if (this.platform.isServer) {
      this.ready = true;
      return;
    }
    this.getTournament().subscribe({
      next: () => this.ready = true,
      error: () => this.ready = true
    });
  }

  updateMyProfile(profile: Partial<ZlanPlayer>) {
    return this.http.put<ZlanPlayer>(`${environment.coreUrl}/me?v=${Date.now()}`, profile).pipe(
      tap((result) => this.zlanProfile!.player = { ...this.zlanProfile!.player, ...result })
    );
  }

  checkTournament(): Observable<ZlanCurrentTournament> {
    if (this.tournament) {
      return of(this.tournament);
    }
    if (this.tournamentLoad$) {
      return this.tournamentLoad$;
    }
    this.tournamentLoad$ = this.getTournament().pipe(
      mergeMap(() => {
        if (!this.tournament) {
          return throwError(() => new Error('Aucun tournoi trouvé'));
        }
        return of(this.tournament);
      }),
      tap(() => this.tournamentLoad$ = null)
    )
    return this.tournamentLoad$;
  }

  checkTournamentAndProfile() {
    return this.checkTournament().pipe(
      switchMap(() => {
        if (!this.zlanProfile) {
          return this.getUserInfo();
        }
        return of(this.zlanProfile);
      })
    )
  }

  getUserInfo(force?: boolean) {
    if (this.platform.isServer) {
      return of(null);
    }
    if (this.zlanProfile && !force) {
      return of(this.zlanProfile);
    }
    if (this.loadProfile$) {
      return this.loadProfile$;
    }
    this.loadProfile$ = this.http.get<ZlanUserInfo>(`${environment.coreUrl}/userinfo?v=${Date.now()}`).pipe(
      tap(profile => this.zlanProfile = profile),
      tap(() => this.loadProfile$ = null)
    );
    return this.loadProfile$;
  }

  getTournament() {
    return this.http.get<ZlanCurrentTournament>(`${environment.coreUrl}/tournament`).pipe(
      tap((tournament: ZlanCurrentTournament) => this.tournament = tournament)
    );
  }


  getMyTeam() {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.get<ZlanMyTeam>(`${environment.coreUrl}/tournament/teams?v=${Date.now()}`)),
      tap(team => this.myTeam = team)
    )
  }

  leaveGroup() {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.delete(`${environment.coreUrl}/tournament/teams`)),
      tap(() => this.myTeam = { hasTeam: false, team: null })
    )
  }

  joinGroup(code: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.post<ZlanTeam>(`${environment.coreUrl}/tournament/teams/join/${code}`, {})),
      tap(team => this.myTeam = { hasTeam: true, team: team })
    )
  }

  createGroup(name: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap((t) => this.http.post<ZlanTeam>(`${environment.coreUrl}/tournament/teams`, {
        name,
        flair: 'normie'
      })),
      tap(team => this.myTeam = { hasTeam: true, team: team })
    )
  }

  registerToDraw(drawId: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.post(`${environment.coreUrl}/tournament/registration/draw/${drawId}`, {}))
    )
  }

  unregisterToDraw(drawId: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.delete(`${environment.coreUrl}/tournament/registration/draw/${drawId}`, {}))
    )
  }

  registerToQualif(qualifId: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.post(`${environment.coreUrl}/tournament/registration/qualif/${qualifId}`, {}))
    )
  }

  unregisterToQualif(qualifId: string) {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => this.http.delete(`${environment.coreUrl}/tournament/registration/qualif/${qualifId}`, {}))
    )
  }

  getRegistrations() {
    return this.checkTournamentAndProfile().pipe(
      mergeMap(() => {
        return this.http.get<ZlanEntry[]>(`${environment.coreUrl}/tournament/registration?v=${Date.now()}`)
      }),
      tap((registrations: ZlanEntry[]) => {
        this.registrations = registrations
      })
    )
  }
}
