import { Inject, Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { map, mergeMap, of, switchMap, tap } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from "@Env";
import { ApiService } from './api.service';
import { ToastrService } from 'ngx-toastr';
import { PlatformService } from './platform.service';

export interface KeycloakLinkedAccount {
  connected: boolean;
  displayName: string;
  linkedUsername: string;
  providerAlias: string;
  providerName: string;
  social: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  user: Keycloak.KeycloakProfile | null = null;
  linkedAccounts: Record<string, KeycloakLinkedAccount> = {};
  ready = false;
  loggedIn = false;

  constructor(public keycloak: KeycloakService,
              public platform: PlatformService,
              private http: HttpClient,
              public api: ApiService,
              private toastr: ToastrService) {}

  init() {
    if (this.platform.isServer) {
      this.ready = true;
      return;
    }

    fromPromise(this.keycloak.isLoggedIn()).pipe(
      switchMap(isLoggedIn => {
        this.loggedIn = isLoggedIn;
        if (isLoggedIn) {
          return fromPromise(this.refresh().then(() => this.keycloak.loadUserProfile()))
        }
        return of(null)
      }),
      switchMap(profile => {
        if (profile) {
          this.user = profile;
          return this.api.getUserInfo();
        } else {
          return of(null)
        }
      }),
      switchMap(() => this.getLinkedAccounts())
    ).subscribe({
      next: () => {
        this.ready = true;
      },
      error: (err) => {
        if (err === 'Not authenticated') {
          this.loggedIn = false;
          this.ready = true;
          if (this.loggedIn) {
            this.keycloak.logout(window.location.origin).then();
          }
        }
        this.ready = true;
      }
    })
  }

  async refresh() {
    if (this.keycloak.isTokenExpired()) {
      await this.keycloak.updateToken()
    }
  }

  login() {
    this.keycloak.login({
      prompt: 'login',
      idpHint: 'oidc',
      redirectUri: window.location.href
    })
  }

  getLinkedAccounts() {
    if (this.loggedIn) {
      return this.http.get<KeycloakLinkedAccount[]>(`${this.keycloak.getKeycloakInstance().authServerUrl}/realms/${environment.realm}/account/linked-accounts`).pipe(
        tap((accounts: any[]) => {
          this.linkedAccounts = accounts.reduce((acc, account) => {
            acc[account.providerAlias] = account;
            return acc;
          }, {})
        })
      )
    } else {
      return of([]);
    }
  }

  linkAccount(providerId: string) {
    const getProviderNonce = `${this.keycloak.getKeycloakInstance().authServerUrl}/realms/${environment.realm}/account/linked-accounts/${providerId}?redirectUri=${encodeURIComponent(window.location.href)}`
    this.http.get<{ hash: string, nonce: string }>(getProviderNonce).pipe(
      map(({nonce, hash}) => `${this.keycloak.getKeycloakInstance().authServerUrl}/realms/${environment.realm}/broker/${providerId}/link?nonce=${nonce}&client_id=account-console&hash=${hash}&redirect_uri=${encodeURIComponent(window.location.href)}`)
    ).subscribe( {
      next: (redirectUrl) => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          this.toastr.error('Impossible d\'accéder au service de connexion');
        }
      },
      error: (err) => {
        this.toastr.error(err.message, 'Impossible d\'accéder au service de connexion');
      }
    });
  }

  logout() {
    this.keycloak.logout(window.location.origin).then();
  }

}
