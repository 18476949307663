<div class="splash" *ngIf="!auth.ready || !api.ready || mandatorySplash" [@splashOut]>
  <zlan-splash></zlan-splash>
</div>


<div id="app">
  <app-cubes *ngIf="platform.isBrowser"></app-cubes>
  <zlan-header></zlan-header>
  <div class="wrapper" [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
  </div>
  <zlan-footer></zlan-footer>
</div>

