import { Component, HostListener, Input } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CubesService } from "../../services/cubes.service";

@Component({
  selector: 'app-cubes',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './cubes.component.html',
  styleUrls: ['./cubes.component.scss']
})
export class CubesComponent {

  cubesConfig = this.cubeService.cubesConfig;

  constructor(private cubeService: CubesService) {
    this.cubeService.positionChanged.subscribe(() => {
      this.cubesConfig = this.cubeService.cubesConfig;
    })
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    const cubes = document.querySelectorAll('.floating-cube');
    cubes.forEach(cube => {
      const speed = parseInt(cube.getAttribute('data-speed') ?? '1') || 1;
      const x = (window.innerWidth - event.pageX * speed) / 100;
      const y = (window.innerHeight - event.pageY * speed) / 100;
      // get style
      const style = cube.getAttribute('style') ?? '';
      // replace or append transform
      if (style.includes('transform')) {
        cube.setAttribute('style', style.replace(/transform:.*;/, `transform: translateX(${x}px) translateY(${y}px);`));
      } else {
        cube.setAttribute('style', `${style} transform: translateX(${x}px) translateY(${y}px);`);
      }
    });
  }

}
