import {
  animate, animateChild,
  animation, AnimationReferenceMetadata,
  AnimationTriggerMetadata,
  group,
  keyframes, query,
  style,
  transition,
  trigger, useAnimation
} from "@angular/animations";
import { IAnimationOptions } from "angular-animations/common/interfaces";

function useAnimationIncludingChildren(animation: AnimationReferenceMetadata, options?: IAnimationOptions) {
  return [
    ...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), { optional: true })] : []),
    group([
      useAnimation(animation),
      ...(!options || !options.animateChildren || options.animateChildren === 'together'
        ? [query('@*', animateChild(), { optional: true })]
        : [])
    ]),
    ...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), { optional: true })] : [])
  ];
}

const zoomIn = () =>
  animation(
    group([
      animate(
        '{{duration}}ms {{delay}}ms',
        keyframes([
          style({ opacity: 0, easing: 'ease', offset: 0 }),
          style({ opacity: 1, easing: 'ease', offset: 0.5 }),
          style({ opacity: 1, easing: 'ease', offset: 1 })
        ])
      ),
      animate(
        '{{duration}}ms {{delay}}ms',
        keyframes([
          style({ visibility: 'visible', transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 0 }),
          style({ transform: 'scale3d(10, 10, 10)', easing: 'ease', offset: 1 })
        ])
      ),
      animate(
        '{{duration}}ms {{delay}}ms',
        keyframes([style({ opacity: 1, easing: 'ease', offset: 0 }), style({ opacity: 0, easing: 'ease', offset: 1 })])
      )
    ])
  );

export function zoomInOnLeaveAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'zoomInOnEnter', [
    transition(':leave', [style({ visibility: 'hidden' }), ...useAnimationIncludingChildren(zoomIn(), options)], {
      params: {
        delay: (options && options.delay) || 0,
        duration: (options && options.duration)
      }
    })
  ]);
}
