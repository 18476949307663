import { Component, NgZone } from '@angular/core';
import { PlatformService } from "./services/platform.service";
import { ChildrenOutletContexts } from "@angular/router";
import { routerAnimations } from "./router-animation";
import { zoomInOnLeaveAnimation } from "./animations/zoomInOnLeaveAnimation";
import { AuthService } from "./services/auth.service";
import { ApiService } from './services/api.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerAnimations,
    zoomInOnLeaveAnimation({ duration: 500, anchor: 'splashOut' }),
  ]
})
export class AppComponent {

  mandatorySplash = true;

  constructor(public platform: PlatformService,
              private contexts: ChildrenOutletContexts,
              public auth: AuthService,
              public clipboard: ClipboardService,
              public toastr: ToastrService,
              public meta: Meta,
              public api: ApiService) {
    this.platform.checkVersion();
    this.auth.init();
    this.api.init();
    this.setMetaTags();
    this.clipboard.copyResponse$.subscribe(() => {
      this.toastr.success('Copié dans le presse-papier');
    });
    if (this.platform.isBrowser) {
      this.platform.setTimeout(() => this.mandatorySplash = false, 10);
    }
  }

  setMetaTags() {
    this.meta.addTag({property: 'og:type', content: 'website'});
    this.meta.addTag({property: 'og:title', content: 'Z LAN 2024'});
    this.meta.addTag({property: 'og:description', content: 'La Z LAN est une compétition multigaming qui se déroule sur 11 jeux différents. Regroupant 198 joueurs pendant 3 jours, les participants s’affrontent dans l’espoir de remporter leur part des 52 024€.'});
    this.meta.addTag({property: 'og:url', content: 'https://z-lan.fr/'})
    this.meta.addTag({property: 'og:image', content: 'https://z-lan.fr/assets/logo.svg'})

    this.meta.addTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.addTag({name: 'twitter:title', content: 'Z LAN 2024'});
    this.meta.addTag({name: 'twitter:description', content: 'La Z LAN est une compétition multigaming qui se déroule sur 11 jeux différents. Regroupant 198 joueurs pendant 3 jours, les participants s’affrontent dans l’espoir de remporter leur part des 52 024€.'});
    this.meta.addTag({name: 'twitter:url', content: 'https://z-lan.fr/'})
    this.meta.addTag({name: 'twitter:image', content: 'https://z-lan.fr/assets/logo.svg'})
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
