import { Injectable } from "@angular/core";
import { BehaviorSubject, filter, map, mergeMap, Subject, switchMap } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

export interface CubeConfig {
  zIndex: number;
  top: string;
  left: string;
  size: string;
  speed: number;
}

export interface CubesConfig {
  display: boolean;
  cube1: CubeConfig;
  cube2: CubeConfig;
  cube3: CubeConfig;
  cube4: CubeConfig;
}

const defaultConfig = {
  display: true,
  cube1: {
    zIndex: 1000,
    top: '20%',
    left: '80%',
    size: '140px',
    speed: -1
  },
  cube2: {
    zIndex: 0,
    top: '15%',
    left: '70%',
    size: '120px',
    speed: -2
  },
  cube3: {
    zIndex: 0,
    top: '65%',
    left: '82%',
    size: '100px',
    speed: 1
  },
  cube4: {
    zIndex: 1000,
    top: '75%',
    left: '70%',
    size: '170px',
    speed: 2
  }
}

@Injectable({providedIn: 'root'})
export class CubesService {

  cubesConfig: CubesConfig = defaultConfig;

  positionChanged = new BehaviorSubject(this.cubesConfig);

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router.routerState.snapshot),
        map((state) => {
            let route = state.root;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }
        ),
        map((route) => route.data)
      )
      .subscribe((data) => {
        if (Object.hasOwn(data, 'cubes')) {
          this.cubesConfig = data['cubes'];
        } else {
          this.cubesConfig = defaultConfig;
        }
        this.positionChanged.next(this.cubesConfig);
      });
  }
}
