import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'informations', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) },
  { path: 'format', loadChildren: () => import('./format/format.module').then(m => m.FormatModule) },
  { path: 'inscriptions', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule) },
  {
    path: '**',
    redirectTo: '/'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
