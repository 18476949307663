<div class="partners">
  <a target="_blank" href="https://bit.ly/HolyZLAN"><img alt="Holy" src="assets/partners/holy.webp"></a>
  <a target="_blank" href="https://www.omen.com/fr/fr.html"><img alt="OMEN" src="assets/partners/omen.webp"></a>
  <a target="_blank" href="https://bit.ly/3BXwSC6"><img alt="Cdiscount" ngSrc="assets/partners/cdiscount.webp" height="45" width="149"></a>
</div>
<div class="footer">
  <div class="legal">
    <span>ZQSD Productions &copy; 2024 - v{{version}}</span>
  </div>
  <div class="socials">
    <a target="_blank" href="https://www.twitch.tv/zerator"><i class="fa-brands fa-lg fa-twitch"></i></a>
    <a target="_blank" href="https://twitter.com/ZLAN_FR"><i class="fa-brands fa-lg fa-x-twitter"></i></a>
    <a target="_blank" href="https://www.tiktok.com/@zlan_fr/"><i class="fa-brands fa-lg fa-tiktok"></i></a>
    <a target="_blank" href="https://instagram.com/zlan_fr"><i class="fa-brands fa-lg fa-instagram"></i></a>
  </div>
  <div class="links">
    <a routerLink="/informations/mentions-legales">Mentions légales</a>
    <a routerLink="/informations/confidentialite">Politique de confidentialité</a>
  </div>
</div>
