<div class="header">
  <i class="fa-solid fa-bars fa-2x" (click)="menuOpened = true"></i>
  <div class="logo" routerLink="/" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="'hidden'"><img ngSrc="assets/logo.svg" height="60" width="120"></div>
  <div class="left">
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/format">Le format</a>
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/format/jeux">Les jeux</a>
    <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/informations/spectacle">Le spectacle</a>
    <a routerLinkActive="active" routerLink="/inscriptions">Participer</a>
    <a routerLinkActive="active" routerLink="/informations/partenaires">Partenaires</a>
  </div>
  <div class="spacer"></div>
  <div class="right">
    <a class="identifier" *ngIf="auth.loggedIn">
      <span tooltip="Gérer mon compte" placement="bottom" (click)="auth.keycloak.getKeycloakInstance().accountManagement()">{{auth.api.zlanProfile?.player?.name}}</span>
      <i (click)="auth.logout()" tooltip="Déconnexion" placement="bottom" class="fa-regular fa-right-to-bracket"></i>
    </a>
    <a *ngIf="!auth.loggedIn" (click)="auth.login()">Connexion</a>
    <a class="htdplaces" href="https://htdplaces.fr" target="_blank">Billetterie</a>
    <a href="https://docs.google.com/document/d/e/2PACX-1vRW2UIBI0t9JVJEUImIyevqkNEm4vZ1MZuWnJM2vEXS6SkUPqyaETV0N52t6am140Z8a4KJ_QCf7mZr/pub" target="_blank"><i tooltip="FAQ" placement="bottom" class="fa-regular fa-question-circle"></i></a>
  </div>
</div>

<div class="side-menu" [class.opened]="menuOpened">

  <div class="row">
    <i class="fa-solid fa-bars fa-2x" (click)="menuOpened = false"></i>
    <div class="logo" routerLink="/" [routerLinkActiveOptions]="{exact: true}" [routerLinkActive]="'hidden'"><img ngSrc="assets/logo.svg" height="60" width="120"></div>
  </div>
  <a *ngIf="!auth.loggedIn" (click)="auth.login()">Connexion</a>
  <a (click)="menuOpened = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/format">Le format</a>
  <a (click)="menuOpened = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/format/jeux">Les jeux</a>
  <a (click)="menuOpened = false" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true }" routerLink="/informations/spectacle">Le spectacle</a>
  <a (click)="menuOpened = false" routerLinkActive="active" routerLink="/inscriptions">Participer</a>
  <a (click)="menuOpened = false" routerLinkActive="active" routerLink="/informations/partenaires">Partenaires</a>
  <a class="htdplaces" href="https://htdplaces.fr" target="_blank">Billetterie</a>

  <a class="identifier" *ngIf="auth.loggedIn">
    <span tooltip="Gérer mon compte" placement="bottom" (click)="auth.keycloak.getKeycloakInstance().accountManagement()">{{auth.api.zlanProfile?.player?.name}}</span>
    <i (click)="auth.logout()" tooltip="Déconnexion" placement="bottom" class="fa-regular fa-right-to-bracket"></i>
  </a>
</div>
