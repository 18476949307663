import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { AuthService } from "../../services/auth.service";
import { TooltipDirective } from '../tooltip.directive';

@Component({
  selector: 'zlan-header',
  standalone: true,
  imports: [CommonModule, RouterLink, NgOptimizedImage, RouterLinkActive, TooltipDirective],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  menuOpened = false;

  constructor(public auth: AuthService) {
  }

}
