import { Component } from '@angular/core';

@Component({
  selector: 'zlan-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent {

}
