import { APP_INITIALIZER, NgModule, isDevMode, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {provideClientHydration} from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { PlatformService } from "./services/platform.service";
import { RouterModule, RouterOutlet } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HeaderComponent } from "./standalones/header/header.component";
import { CubesComponent } from "./standalones/cubes/cubes.component";
import { FooterComponent } from "./standalones/footer/footer.component";
import { environment } from "@Env";
import { SplashComponent } from "./splash/splash.component";
import { NgOptimizedImage } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import { ClipboardModule } from 'ngx-clipboard';
import 'zone.js/plugins/task-tracking';

function initializeKeycloak(keycloak: KeycloakService, platform: PlatformService, ngZone: NgZone) {
  if (platform.isServer) {
    return () => Promise.resolve();
  }
  return () => keycloak.init({
      config: {
        realm: environment.realm,
        url: environment.authUrl,
        clientId: environment.clientId,
      },
      initOptions: {
        flow: 'implicit',
        checkLoginIframe: true,
        locale: 'fr',
        silentCheckSsoFallback: true,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
      }
    });
}

@NgModule({
  declarations: [
    AppComponent,
    SplashComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    HeaderComponent,
    CubesComponent,
    FooterComponent,
    NgOptimizedImage,
    ClipboardModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    provideClientHydration(),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, PlatformService, NgZone]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
/*  constructor(ngZone: NgZone) {
    /!**
     * CONFIGURE how long to wait (in seconds)
     * before the pending tasks are dumped to the console.
     *!/
    const WAIT_SECONDS = 10;

    console.log(
      `⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`
    );

    // Run the debugging `setTimeout` code outside of
    // the Angular Zone, so it's not considered as
    // yet another pending Zone Task:
    ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        // Access the NgZone's internals - TaskTrackingZone:
        const TaskTrackingZone = (ngZone as any)._inner
          ._parent._properties.TaskTrackingZone;

        // Print to the console all pending tasks
        // (micro tasks, macro tasks and event listeners):
        console.debug('👀 Pending tasks in NgZone: 👀');
        console.debug({
          microTasks: TaskTrackingZone.getTasksFor('microTask'),
          macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
          eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
        });

        // Advice how to find the origin of Zone tasks:
        console.debug(
          `👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`
        );
      }, 1000 * WAIT_SECONDS);
    });
  }*/
}
