import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { SwUpdate } from "@angular/service-worker";

@Injectable({ providedIn: 'root' })
export class PlatformService {

  localStorage: Storage | null = this.isBrowser ? localStorage : null;

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get isServer() {
    return !this.isBrowser;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private sw: SwUpdate) {}

  checkVersion() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.sw.isEnabled) {
        this.sw.checkForUpdate().then((isAvailable) => {
          console.warn('new version available', isAvailable);
          if (isAvailable) {
            this.sw.activateUpdate().then(() => {
              this.setItem('update', 'true');
              window.location.reload();
            });
          } else {
            this.removeItem('update');
          }
        });
      }
    }
  }

  getItem(key: string) {
    return this.localStorage?.getItem(key);
  }

  removeItem(key: string) {
    this.localStorage?.removeItem(key);
  }

  setItem(key: string, value: string) {
    this.localStorage?.setItem(key, value);
  }

  setTimeout(callback: () => void, timeout: number) {
    this.isBrowser ? setTimeout(callback, timeout) : callback();
  }

  setInterval(callback: () => void, timeout: number) {
    this.isBrowser ? setInterval(callback, timeout) : callback();
  }
}
