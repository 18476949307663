<ng-container *ngIf="cubesConfig.display">
  <div class="floating-cube cube-1"
       [style.top]="cubesConfig.cube1.top"
       [style.left]="cubesConfig.cube1.left"
       [style.z-index]="cubesConfig.cube1.zIndex"
       [attr.data-speed]="cubesConfig.cube1.speed">
    <img ngSrc="assets/shapes/CUBE01.png" height="599" width="567" [style.width]="cubesConfig.cube1.size">
  </div>
  <div class="floating-cube cube-2"
       [style.top]="cubesConfig.cube2.top"
       [style.left]="cubesConfig.cube2.left"
       [style.z-index]="cubesConfig.cube2.zIndex"
       [attr.data-speed]="cubesConfig.cube2.speed">
    <img ngSrc="assets/shapes/CUBE02.png" height="548" width="558" [style.width]="cubesConfig.cube2.size">
  </div>
  <div class="floating-cube cube-3"
       [style.top]="cubesConfig.cube3.top"
       [style.left]="cubesConfig.cube3.left"
       [style.z-index]="cubesConfig.cube3.zIndex"
       [attr.data-speed]="cubesConfig.cube3.speed">
    <img ngSrc="assets/shapes/CUBE03.png" height="599" width="567" [style.width]="cubesConfig.cube3.size">
  </div>
  <div class="floating-cube cube-4"
       [style.top]="cubesConfig.cube4.top"
       [style.left]="cubesConfig.cube4.left"
       [style.z-index]="cubesConfig.cube4.zIndex"
       [attr.data-speed]="cubesConfig.cube4.speed">
    <img priority="priority" ngSrc="assets/shapes/CUBE04.png" height="567" width="518" [style.width]="cubesConfig.cube4.size">
  </div>
</ng-container>
